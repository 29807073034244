<template>
  <div>
    <MaxWinWidget
      v-if="gameId"
      v-model="isShowMaxWinWidget"
      :game-id="gameId"
      class="min-max-widget"
    />
    <div class="game-footer">
      <div class="game-footer-inner">
        <div class="demo-toggler">
          <span :class="{ inactive: demoToggle }">
            {{ t('casinoGames.demoMode') }}
          </span>
          <StToggle
            v-model="demoToggle"
            size="s"
            :disabled="!props.hasDemoMode"
            color="secondary"
            data-t="demo-toggle"
          />
          <span
            :class="{ inactive: !demoToggle }"
            data-t="play-on-money-label-k1oj"
            >{{ t('casinoGames.playOnMoney') }}</span
          >
        </div>
        <div class="top-row-right">
          <StButton
            size="l"
            class="button-icon"
            type="text-white"
            icon="big-wins"
            @click="isShowMaxWinWidget = !isShowMaxWinWidget"
          />
          <StButton
            size="l"
            type="text-white"
            icon="expand-1"
            data-t="open-fullscreen-button"
            @click="emit('toggleFullscreen')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GameMode } from '../../../types'
import MaxWinWidget from '../../GamePage/parts/MaxWinWidget/MaxWinWidget.vue'

const props = defineProps<{
  gameId: number
  hasDemoMode?: boolean
}>()

const emit = defineEmits<{
  toggleFullscreen: []
}>()

const { t } = useI18n()
const isShowMaxWinWidget = ref(false)

const gameMode = useRouteQuery<GameMode>('mode', 'real')
const demoToggle = computed({
  get: () => gameMode.value === 'real',
  set: (newValue: boolean) => {
    gameMode.value = newValue ? 'real' : 'demo'
  },
})
</script>

<style scoped>
.game-footer {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  width: 100vw;

  background-color: var(--background-base);
}

.game-image {
  object-fit: cover;
  border-radius: var(--border-radius-200);
}

.central-widget {
  position: absolute;
  top: -44px;
  left: 50%;
  transform: translateX(-50%);

  text-align: center;
}

.game-footer-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-050) var(--spacing-100);

  &.button-icon {
    padding: var(--spacing-100);
  }
}

.top-row-right {
  display: flex;
  gap: var(--spacing-100);
}

.text {
  padding: 0 var(--spacing-200) var(--spacing-250) var(--spacing-200);
  text-align: center;
}

.game-name {
  all: unset;
  font: var(--mobile-title-1-semibold);
}

.currency-widget-wrapper {
  padding: 0 var(--spacing-200) var(--spacing-300) var(--spacing-200);
}

.demo-toggler {
  display: grid;
  grid-template-columns: max-content 36px max-content;
  gap: var(--spacing-100);
  align-items: center;

  span {
    font: var(--mobile-text-medium);
  }
}

.inactive {
  color: var(--text-secondary);
}

.min-max-widget {
  position: absolute !important;
  top: 56px;
  right: 0;
  left: 0;
}
</style>
